import { FunctionComponent } from 'react';
import styles from './VdigmNews.module.css';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import axios from 'axios';


import Main from './Main.tsx'

interface News {
    titleKo: string;
    titleEn: string;
    mediaKo: string;
    mediaEn: string;
    url: string;
}

const VdigmNews: FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    // const [newsObjArr, setNewsObjArr] = useState<News[]>([{
    //     titleKo: "",
    //     titleEn: "",
    //     mediaKo: "",
    //     mediaEn: "",
    //     url: "",
    // }]);
    const [newsObj, setNewsObj] = useState<News>({
        titleKo: "",
        titleEn: "",
        mediaKo: "",
        mediaEn: "",
        url: "",
    });

    const [newsObjArr, setNewsObjArr] = useState<News[]>([{
        titleKo: "Youngwan Kim, CEO of Vdigm, Inc.",
        titleEn: "Youngwan Kim, CEO of Vdigm, Inc.",
        mediaKo: "Alchedek",
        mediaEn: "Alchedek",
        url: "https://www.alchedek.com/news/articleView.html?idxno=198",
    },{
        titleKo: "[서울AI허브 2025] 스트리머를 위한 3D 아바타 기반 AI NPC·AI 에이전트 서비스 개발 ‘브이다임’",
        titleEn: "[Seoul AI Hub 2025]Development of 3D Avatar-based AI NPCs and AI Agent Services for Streamers by Vdigm",
        mediaKo: "IT 조선",
        mediaEn: "IT Chosun",
        url: "https://it.chosun.com/news/articleView.html?idxno=2023092125831",
    },{
        titleKo: "브이다임, 딥러닝 기반 ‘실감형 3D 아바타' 제작 지원",
        titleEn: "Vdigm supports the production of 'realistic 3D avatars' based on deep learning",
        mediaKo: "IT 조선",
        mediaEn: "IT Chosun",
        url: "https://it.chosun.com/news/articleView.html?idxno=2023092104124",
    },
    {
        titleKo: "실감형 메타버스 서비스 기업, 브이다임",
        titleEn: "Vdgim, a Realistic Metaverse Service Company",
        mediaKo: "경제인 뉴스",
        mediaEn: "News In Economy",
        url: "https://www.newseconomy.kr/news/articleView.html?idxno=5714",
    },
    {
        titleKo: "비주얼 AI 기반 실감형 메타버스 서비스 기업 ‘브이다임’",
        titleEn: "Visual AI-based realistic metaverse service company 'Vdigm'",
        mediaKo: "매거진 한경",
        mediaEn: "Magazine Hankyung",
        url: "https://magazine.hankyung.com/job-joy/article/202212043806d",
    },
    {
        titleKo: "AI 메타버스 스타트업 ‘브이다임', 팁스(TIPS) 선정",
        titleEn: "AI Metaverse Start-up 'Vdigm' Selected as TIPS",
        mediaKo: "벤처스퀘어",
        mediaEn: "VentureSquare",
        url: "https://www.venturesquare.net/856659",
    }
    ]);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await axios.get('https://vdigm.com/api/news');
                const newsData = response.data;
                setNewsObjArr(newsData.map((item: News) => ({
                    titleKo: item.titleKo,
                    titleEn: item.titleEn,
                    mediaKo: item.mediaKo,
                    mediaEn: item.mediaEn,
                    url: item.url
                })));

            } catch (error) {
                console.error('Error fetching news:', error);
            }
        };

        fetchNews(); // 뉴스 데이터 가져오기 호출
    }, []);

    return (
        <div className={styles.news}>
            {/* <div className={styles.div1}>브이다임 소식</div> */}
            {/* <b className={styles.it}>[IT 조선] 브이다임, 딥러닝 기반 ‘실감형 3D 아바타' 제작 지원</b>
            <b className={styles.b}>바로가기</b>
            <b className={styles.b1}>[경제인 뉴스] 실감형 메타버스 서비스 기업, 브이다임</b>
            <b className={styles.b2}>바로가기</b>
            <b className={styles.ai}>[매거진 한경] 비주얼 AI 기반 실감형 메타버스 서비스 기업 ‘브이다임’</b>
            <b className={styles.b3}>바로가기</b>
            <b className={styles.ai1}>[벤처스퀘어] AI 메타버스 스타트업 ‘브이다임', 팁스(TIPS) 선정</b>
            <b className={styles.b4}>바로가기</b> */}

            {/* <div className={styles.div1}>{t(`home:vdigmNews:vdigmNews`)}</div>
            {newsObjArr.map((news, index) => (
                <div key={index}>
                    <a href={news.url} className={styles.bb} target="_blank">
                        {t('home:vdigmNews:morePc')}
                    </a>
                    <b className={styles.title}>{`[${news.media}] ${news.titleKo}`}</b>
                </div>
            ))} */}
            {currentLanguage === "ko" ? (
                <div className={styles.div1}>{t(`home:vdigmNews:vdigmNews`)}</div>
            ) : (
                <div className={styles.div1En}>{t(`home:vdigmNews:vdigmNews`)}</div>
            )}

            {newsObjArr.map((news, index) => (
                <div key={index} className={styles.title}>
                    
                    {currentLanguage === "ko" ? (
                        <a href={news.url} className={styles.bb} target="_blank">
                            {t('home:vdigmNews:morePc')}
                        </a>
                    ) : (
                        <a href={news.url} className={styles.bbEn} target="_blank">
                            {t('home:vdigmNews:morePc')}
                        </a>
                    )}

                    {currentLanguage === "ko"
                        ? <b className={styles.titleKo}>{`[${news.mediaKo}] ${news.titleKo}`}</b>
                        : <b className={styles.titleEn}>{`[${news.mediaEn}] ${news.titleEn}`}</b>
                    }
                </div>
            ))}

        </div>

    );
};

export default VdigmNews;

