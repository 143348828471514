// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './component/Home.tsx';
import HomePc from './component/pc/Home.tsx';

import Company from './component/Company.tsx';
import CompanyPc from './component/pc/Company.tsx';

import Solution from './component/Solution.tsx';
import SolutionC from './component/SolutionC.tsx';
import SolutionPc from './component/pc/Solution.tsx';

import Tih from './component/Tih.tsx';
import TihPc from './component/pc/Tih.tsx';

import Recruit from './component/Recruit.tsx';
import RecruitPc from './component/pc/Recruit.tsx';

import Contact from './component/Contact.tsx';
import ContactPc from './component/pc/Contact.tsx';


import Header from './component/Header.tsx';
import HeaderPc from './component/pc/Header.tsx';

import Footer from './component/Footer.tsx';
import FooterPc from './component/pc/Footer.tsx';

import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import styles from './component/App.module.css';
import ToTop from './component/ToTop.tsx';
import ToTopPc from './component/pc/ToTop.tsx';

import Login from './component/admin/Login.tsx';


import { BrowserView, MobileView } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';


const App = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const getFontFamily = () => {
    switch (currentLanguage) {
      case 'en':
        return 'Roboto';

      case 'ko':
        return 'NanumSquare, sans-serif';
    }
  }

  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  // 반응형 창 크기 기준에 따라 구분
  const isPc = useMediaQuery({ minWidth: 1280 });
  const isTablet = useMediaQuery({ minWidth: 840, maxWidth: 1279 });
  const isMobile = useMediaQuery({ maxWidth: 839 });

  return (
    <div className={styles.App} style={{ fontFamily: getFontFamily() }}>
      <Routes>

        {/* 관리자 페이지 */}
        <Route path="/admin/login" element={<Login />} />
      </Routes>

      {!isAdminRoute && (
        <>
          {isPc && (
            <>
              <HeaderPc />
              <Routes>
                <Route path="/" element={<HomePc />} />
                <Route path="/company" element={<CompanyPc />} />
                <Route path="/solution" element={<SolutionPc />} />
                <Route path="/tih" element={<TihPc />} />
                <Route path="/recruit" element={<RecruitPc />} />
                <Route path="/contact" element={<ContactPc />} />
              </Routes>
              <FooterPc />
              <ToTopPc />
            </>
          )}
          {isTablet && (
            <>
              <HeaderPc />
              <Routes>
                <Route path="/" element={<HomePc />} />
                <Route path="/company" element={<CompanyPc />} />
                <Route path="/solution" element={<SolutionPc />} />
                <Route path="/tih" element={<TihPc />} />
                <Route path="/recruit" element={<RecruitPc />} />
                <Route path="/contact" element={<ContactPc />} />
              </Routes>
              <FooterPc />
              <ToTopPc />
            </>
          )}
          {isMobile && (
            <>
              <Header />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/company" element={<Company />} />
                <Route path="/solution" element={<Solution />} />
                <Route path="/tih" element={<Tih />} />
                <Route path="/recruit" element={<Recruit />} />
                <Route path="/contact" element={<Contact />} />
              </Routes>
              <Footer />
              <ToTop />
            </>
          )}
        </>
      )}

      {/* <BrowserView>
        <Routes>
          <Route path="/" element={<HomePc />} />
          <Route path="/company" element={<CompanyPc />} />
          <Route path="/solution" element={<SolutionPc />} />
          <Route path="/tih" element={<TihPc />} />
          <Route path="/recruit" element={<RecruitPc />} />
          <Route path="/contact" element={<ContactPc />} />

          <Route path="/admin/login" element={<Login />} />
        </Routes>

        {!isAdminRoute ? (
          <>
            <HeaderPc />
            <FooterPc />
            <ToTopPc />
          </>
        ) : (
          <>
            
          </>
        )}
      </BrowserView>

      <MobileView>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/company" element={<Company />} />
          <Route path="/solution" element={<Solution />} />
          <Route path="/tih" element={<Tih />} />
          <Route path="/recruit" element={<Recruit />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
        <ToTop />
      </MobileView> */}
    </div >

  );
}

export default App;
