import { FunctionComponent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './VdigmNews.module.css';
import React, { useState, useRef, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './Swiper.css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Header from "./Header.tsx";
import Main from "./Main.tsx";
import Youtubes from './VdigmYoutubes.tsx';
import App from '../App.tsx';
import { Autoplay, FreeMode, Pagination, Scrollbar } from 'swiper/modules';
import i18n from "../locales/i18n.ts";
import axios from 'axios';

// IT 조선_2023_11
// https://it.chosun.com/news/articleView.html?idxno=2023092104124

// 경제인뉴스_2023_09
// https://www.newseconomy.kr/news/articleView.html?idxno=5714

// 매거진 한경_2022_12
// https://magazine.hankyung.com/job-joy/article/202212043806d

// 벤처스퀘어_2022_06
// https://www.venturesquare.net/856659

// const vdigmNews: VdigmNews[] = [
//     {
//         title: "[벤처스퀘어] AI 메타버스 스타트업 ‘브이다임', TIPs...",
//         more: "더보기",
//         url: "https://www.venturesquare.net/856659",
//     },
//     {
//         title: "[매거진 한경] 비주얼 AI 비주얼 AI 기반 실감형 메타...",
//         more: "더보기",
//         url: "https://magazine.hankyung.com/job-joy/article/202212043806d",
//     },
//     {
//         title: "[경제인 뉴스] 실감형 베타버스 서비스 기업, 브이다...",
//         more: "더보기",
//         url: "https://www.newseconomy.kr/news/articleView.html?idxno=5714",
//     },
//     {
//         title: "[IT 조선] 브이다임, 딥러닝 기반 ‘실감형 3D 아바타'...",
//         more: "더보기",
//         url: "https://it.chosun.com/news/articleView.html?idxno=2023092104124",
//     },
// ]

interface News {
    titleKo: string;
    titleEn: string;
    mediaKo: string;
    mediaEn: string;
    url: string;
}

const VdigmNews: FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    // const [newsObjArr, setNewsObjArr] = useState<News[]>([{
    //     titleKo: "",
    //     titleEn: "",
    //     mediaKo: "",
    //     mediaEn: "",
    //     url: "",
    // }]);
    const [newsObj, setNewsObj] = useState<News>({
        titleKo: "",
        titleEn: "",
        mediaKo: "",
        mediaEn: "",
        url: "",
    });

    const [newsObjArr, setNewsObjArr] = useState<News[]>([{
        titleKo: "Youngwan Kim, CEO of Vdigm, Inc.",
        titleEn: "Youngwan Kim, CEO of Vdigm, Inc.",
        mediaKo: "Alchedek",
        mediaEn: "Alchedek",
        url: "https://www.alchedek.com/news/articleView.html?idxno=198",
    },{
        titleKo: "[서울AI허브 2025] 스트리머를 위한 3D 아바타 기반 AI NPC·AI 에이전트 서비스 개발 ‘브이다임’",
        titleEn: "[Seoul AI Hub 2025]Development of 3D Avatar-based AI NPCs and AI Agent Services for Streamers by Vdigm",
        mediaKo: "IT 조선",
        mediaEn: "IT Chosun",
        url: "https://it.chosun.com/news/articleView.html?idxno=2023092125831",
    },{
        titleKo: "브이다임, 딥러닝 기반 ‘실감형 3D 아바타' 제작 지원",
        titleEn: "Vdigm supports the production of 'realistic 3D avatars' based on deep learning",
        mediaKo: "IT 조선",
        mediaEn: "IT Chosun",
        url: "https://it.chosun.com/news/articleView.html?idxno=2023092104124",
    },
    {
        titleKo: "실감형 메타버스 서비스 기업, 브이다임",
        titleEn: "Vdgim, a Realistic Metaverse Service Company",
        mediaKo: "경제인 뉴스",
        mediaEn: "News In Economy",
        url: "https://www.newseconomy.kr/news/articleView.html?idxno=5714",
    },
    {
        titleKo: "비주얼 AI 기반 실감형 메타버스 서비스 기업 ‘브이다임’",
        titleEn: "Visual AI-based realistic metaverse service company 'Vdigm'",
        mediaKo: "매거진 한경",
        mediaEn: "Magazine Hankyung",
        url: "https://magazine.hankyung.com/job-joy/article/202212043806d",
    },
    {
        titleKo: "AI 메타버스 스타트업 ‘브이다임', 팁스(TIPS) 선정",
        titleEn: "AI Metaverse Start-up 'Vdigm' Selected as TIPS",
        mediaKo: "벤처스퀘어",
        mediaEn: "VentureSquare",
        url: "https://www.venturesquare.net/856659",
    }
    ]);

    // 텍스트를 기준에 맞게 자름
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch(
            'http://vdigm.com/api/newsPost', {
            method: "post",
            body: JSON.stringify({
                titleKo: newsObj.titleKo,
                titleEn: newsObj.titleEn,
                mediaKo: newsObj.mediaKo,
                mediaEn: newsObj.mediaEn,
                url: newsObj.url,
            }),
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        });
        const result = await response.json();
        console.warn(result);
        if (result) {
            alert("Data saved succesfully");
            setNewsObjArr(prevNews => [...prevNews, {
                titleKo: result.titleKo,
                titleEn: result.titleEn,
                mediaKo: result.mediaKo,
                mediaEn: result.mediaEn,
                url: result.url,
            }]);
            setNewsObj({
                titleKo: "",
                titleEn: "",
                mediaKo: "",
                mediaEn: "",
                url: "",
            });
        };
    };

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await axios.get('https://vdigm.com/api/news');
                const newsData = response.data;
                setNewsObjArr(newsData.map((item: News) => ({
                    titleKo: item.titleKo,
                    titleEn: item.titleEn,
                    mediaKo: item.mediaKo,
                    mediaEn: item.mediaEn,
                    url: item.url
                })));

            } catch (error) {
                console.error('Error fetching news:', error);
            }
        };

        fetchNews(); // 뉴스 데이터 가져오기 호출
    }, []);

    return (
        <div>
            {/* <div className={styles.manager}>
                <form onSubmit={handleOnSubmit}>
                    <input
                        type="text"
                        placeholder="titleKo"
                        value={newsObj.titleKo}
                        onChange={(e) => setNewsObj({
                            ...newsObj, titleKo: e.target.value
                        })}
                    />
                    <input
                        type="text"
                        placeholder="titleEn"
                        value={newsObj.titleEn}
                        onChange={(e) => setNewsObj({
                            ...newsObj, titleEn: e.target.value
                        })}
                    />
                    <input
                        type="text"
                        placeholder="media"
                        value={newsObj.media}
                        onChange={(e) => setNewsObj({
                            ...newsObj, media: e.target.value
                        })}
                    />
                    <input
                        type="text"
                        placeholder="url"
                        value={newsObj.url}
                        onChange={(e) => setNewsObj({
                            ...newsObj, url: e.target.value
                        })}
                    />
                    <button type="submit">Submit</button>
                </form>
                <button onClick={addMainImage}>Add Image</button>
            </div> */}
            {currentLanguage === "ko" ? (
                <div className={styles.divKo}>
                    {t(`home:vdigmNews:vdigmNews`)}
                </div>) : (
                <div className={styles.divEn}>
                    {t(`home:vdigmNews:vdigmNews`)}
                </div>
            )}

            {newsObjArr.map((news, index) => (
                <div key={index}>
                    {currentLanguage === "ko" ? (
                        <a href={news.url} className={styles.bbKo} target="_blank" rel="noopener noreferrer">
                            {t('home:vdigmNews:more')}
                        </a>
                    ) : (
                        <a href={news.url} className={styles.bbEn} target="_blank" rel="noopener noreferrer">
                            {t('home:vdigmNews:more')}
                        </a>
                    )}
                    {currentLanguage === "ko" ? (

                        (news.mediaKo.length + news.titleKo.length < 30) ? (
                            <b className={styles.titleKo}>{`[${news.mediaKo}] ${news.titleKo}`}</b>
                        ) : (
                            <b className={styles.titleKo}>{`${truncateText(`[${news.mediaKo}] ${news.titleKo}`, 31)}`}</b>
                        )
                    ) : (
                        (news.mediaEn.length + news.titleEn.length < 43) ? (
                            <b className={styles.titleEn}>{`[${news.mediaEn}] ${news.titleEn}`}</b>
                        ) : (
                            <b className={styles.titleEn}>{`${truncateText(`[${news.mediaEn}] ${news.titleEn}`, 44)}`}</b>
                        )
                    )}
                </div>
            ))}

            {/* <div className={styles.div}>
                {t(`home:vdigmNews:vdigmNews`)}</div>
                {newsObjArr.map((news, index) => (
                    <div key={index}>
                        <a href={news.url} target="_blank" rel="noopener noreferrer">
                            <b className={styles.bb}>{t('home:vdigmNews:more')}</b>
                        </a>
                        {currentLanguage === "ko" ? (
                            <b className={styles.titleKo}>{`${truncateText(`[${news.media}] ${news.titleKo}`, 29)}`}</b>
                        ) : (
                            <b className={styles.titleEn}>{`${truncateText(`[${news.media}] ${news.titleEn}`, 43)}`}</b>
                        )
                        }
                    </div>
                ))} */}


            {/* <div className={styles.div}>
                {t(`home:vdigmNews:vdigmNews`)}
                {newsObjArr.map((news, index) => (
                    <div key={index}>
                        <a href={news.url} target="_blank">
                            <b className={styles.bb}>{t('home:vdigmNews:more')}</b>
                        </a>
                        {currentLanguage === "ko" ? (
                            <b className={styles.title}>{`[${news.media}] ${news.titleKo}`}</b>
                        ) : (
                            <b className={styles.title}>{`[${news.media}] ${news.titleEn}`}</b>
                        )
                        }
                    </div>
                ))}
            </div> */}

            {/* <div className={styles.div}>{t(`home:vdigmNews:vdigmNews`)}</div>
            {newsObjArr.map((news, index) => (
                <div key={index}>
                    <a href={news.url} target="_blank">
                        <b className={styles.bb}>{t('home:vdigmNews:more')}</b>
                    </a>
                    {currentLanguage === "ko"
                        
                        ? <b className={styles.title}>{`[${news.media}] ${news.titleKo}`}</b>

                        : <b className={styles.title}>{`[${news.media}] ${news.titleEn}`}</b>
                    }
                </div>
            ))} */}

            {/* <a href={newsArr[3]} target="_blank">
                <b className={styles.b1}>{t(`home:vdigmNews:more`)}</b>
            </a>
            <a href={newsArr[2]} target="_blank">
                <b className={styles.b2}>{t(`home:vdigmNews:more`)}</b>
            </a>
            <a href={newsArr[1]} target="_blank">
                <b className={styles.b3}>{t(`home:vdigmNews:more`)}</b>
            </a>
            <a href={newsArr[0]} target="_blank">
                <b className={styles.b4}>{t(`home:vdigmNews:more`)}</b>
            </a> */}

            {/* <b className={styles.it}>{newsArr[3]}</b>
            <b className={styles.aiAi}>{newsArr[1]}</b>
            <b className={styles.ai1}>{newsArr[0]}</b>
            <b className={styles.b6}>{newsArr[2]}</b> */}
        </div>
    );
};

export default VdigmNews;

